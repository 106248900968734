<template>
  <div class="home" ref="scrollBox" style="height:100vh;overflow-y:scroll;" @scroll="handleScroll">
    <van-list
      v-model:loading="loading"
      :immediate-check="false"
      :finished="finished"
      :finished-text="total > 0 ? '没有更多了' : ''"
      @load="loadList"
    >
      <div
        class="top-view"
        :style="`background: ${color_data.specialColor}`"
      >
        <div class="venue-title item-flex-center fw500 color-white">
          <div
            class="back"
            @click="backAction"
          >
            <img
              src="https://artimg2.artart.cn/image/20220319/922c2ec6ad3e1ba8430624a5373fcdc9.png"
              alt=""
            >
          </div>
          <div>拍卖会</div>
        </div>
        <div class="venue-name">{{venueInfo.title}}</div>
        <div class="venue-info">
          <div class="info">预展时间：{{venueInfo.previewTime}}</div>
          <div class="info">开拍时间：{{venueInfo.startTime}}</div>
          <div class="info">拍卖地点：{{venueInfo.address_name}}</div>
        </div>
      </div>

      <div class="venue-num flex">
        <div class="item item-flex-center">
          <div class="n">{{venueInfo.auction_count ? venueInfo.auction_count.album_count : 0}}</div>
          <div class="t">专场</div>
        </div>
        <div class="item item-flex-center">
          <div class="n">{{venueInfo.auction_count ? venueInfo.auction_count.auction_count : 0}}</div>
          <div class="t">拍品</div>
        </div>
        <div class="item item-flex-center">
          <div class="n">{{venueInfo.click_num}}</div>
          <div class="t">围观</div>
        </div>
      </div>
      <div class="album-title">专场列表</div>
      <div class="album-list">
        <AuctionAlbum
          :content="{ style: 2, renderData: list }"
          :facade="facade"
        ></AuctionAlbum>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed,onActivated,nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { auctionVenueInfoApi, albumListApi } from "@/api/auction";
import { getDiyPage } from "@/api/system";
import { Toast } from "vant";
import { parseTime } from "@/utils/util";
import AuctionAlbum from "@/components/DiyComponent/AuctionAlbum";
import { shareConfig } from '@/utils/share';

export default {
  components: { AuctionAlbum },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const id = ref("");

    const loading = ref(true);
    const pageLoading = ref(true);
    const finished = ref(false);
    const total = ref(0);
    const list = ref([]);
    const facade = ref({});
    const queryParams = ref({
      page: 1,
      limit: 10,
    });
    const venueInfo = ref({}); // 拍卖会信息

    const loadNextPage = () => {
      loading.value = false;
      pageLoading.value = false;
      loadList();
    };

    const loadList = () => {
      if (finished.value) return false;
      if (pageLoading.value) return false;
      loading.value = true;
      pageLoading.value = true;
      albumListApi(queryParams.value)
        .then((res) => {
          total.value = res.data.count;
          if (queryParams.value.page === 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }
          if (list.value.length >= res.data.count) {
            finished.value = true;
          }
          queryParams.value.page += 1;
          pageLoading.value = false;
          loading.value = false;
        })
        .catch((message) => Toast(message));
    };

    onBeforeMount(() => {
      id.value = route.query.id;
      queryParams.value.venue_id = route.query.id;
      getDiyPage({
        type: "album_component",
      })
        .then((res) => {
          facade.value = res.data.config_data;
        })
        .catch((message) => Toast(message));

      auctionVenueInfoApi({ venue_id: id.value })
        .then((res) => {
          shareConfig(
            res.data.title,
            res.data.address_name,
            res.data.image,
            "",
            "",
            location.href
          );
          const year = parseTime(res.data.preview_start_time, "{y}");
          const eyear = parseTime(res.data.preview_start_time, "{y}");
          const month = parseTime(res.data.preview_start_time, "{m}");
          const eMonth = parseTime(res.data.preview_end_time, "{m}");
          let previewTime = "";
          if (month == eMonth && year == eyear) {
            previewTime =
              parseTime(
                res.data.preview_start_time,
                "{y}年{m}月{d}日 {h}:{i}"
              ) +
              " - " +
              parseTime(res.data.preview_end_time, "{d}日 {h}:{i}");
          } else if (year == eyear) {
            previewTime =
              parseTime(
                res.data.preview_start_time,
                "{y}年{m}月{d}日 {h}:{i}"
              ) +
              " - " +
              parseTime(res.data.preview_end_time, "{m}月{d}日 {h}:{i}");
          } else {
            previewTime =
              parseTime(
                res.data.preview_start_time,
                "{y}年{m}月{d}日 {h}:{i}"
              ) +
              " - " +
              parseTime(res.data.preview_end_time, "{y}年{m}月{d}日 {h}:{i}");
          }
          res.data.previewTime = previewTime;
          res.data.startTime = parseTime(
            res.data.start_time,
            "{y}年{m}月{d}日 {h}:{i}"
          );
          venueInfo.value = res.data;
          loading.value = false;
          pageLoading.value = false;
          loadList();
        })
        .catch((message) => Toast(message));
    });
    // 右按钮样式
    const color_data = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };
    let scrollTop = ref(0);
    const scrollBox = ref(null);
    const handleScroll = () => {
      scrollTop.value = scrollBox.value.scrollTop;
    }

    onActivated(() => {
      if(scrollTop.value > 0){
        setTimeout(() => {
          nextTick(() => {
            scrollBox.value.scrollTop = scrollTop.value;
          })
        }, 10)
      }
    })
    return {
      color_data,
      venueInfo,
      list,
      loading,
      finished,
      facade,
      total,

      loadNextPage,
      backAction,
      loadList,

      scrollTop,
      scrollBox,
      handleScroll,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  background: #f7f7f7;
  padding-bottom: 60px;
}
.venue-title {
  font-size: 36px;
  line-height: 60px;
  padding-top: 30px;
  position: relative;

  .back {
    position: absolute;
    left: 0;
    width: 48px;
    height: 48px;
    img {
      width: 48px;
      height: 48px;
      display: block;
    }
  }
}
.top-view {
  padding: 0 32px;
  padding-bottom: 116px;
}

.venue-name {
  padding-top: 22px;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
}

.venue-info {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 34px;
  opacity: 0.7;
}

.venue-info .info {
  padding-top: 8px;
}

.venue-num {
  height: 160px;
  background: #ffffff;
  border-radius: 16px;
  margin: 0 32px;
  margin-top: -80px;
}

.venue-num .item {
  flex: 1;
  flex-direction: column;
}

.venue-num .item .n {
  height: 50px;
  font-size: 36px;
  font-weight: 500;
  color: #060606;
  line-height: 50px;
}

.venue-num .item .t {
  height: 34px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  line-height: 34px;
}

.album-title {
  height: 44px;
  font-size: 32px;
  font-weight: 500;
  color: #060606;
  line-height: 44px;
  margin: 32px;
}

</style>
